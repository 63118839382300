import { eventTracker } from "@/services/tracker";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";

export default {
  state: {
    gender: "FU",
    firstSearch: true,
  },
  mutations: {
    setGender(state, gender) {
      state.gender = gender;
    },
    setFirstSearch(state, firstSearch) {
      state.firstSearch = firstSearch;
    },
  },
  actions: {
    setGender({ commit, dispatch, state }, gender) {
      if (gender != state.gender) dispatch("setNeedsNewRecos");
      eventTracker.emitEvent(EVENT_NAME_ENUM.GENDER_CHANGED, { gender });
      commit("setGender", gender);
    },
    setFirstSearch({ commit }, firstSearch) {
      commit("setFirstSearch", firstSearch);
    },
    reset({ commit }) {
      commit("setFirstSearch", true);
    },
  },
  getters: {},
};
