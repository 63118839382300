<template>
  <button class="toggle-profile" :class="{'open': showProfile}" @click.prevent="toggle">
    <div class="toggle-profile__text">{{ $t('why_are_we_recommending_this_fragrances') }}</div>
    <div class="toggle-profile__icon" />
  </button>
</template>
<script>
export default {
  computed: {
    showProfile() {
      return this.$route.query.showProfile === "true";
    },
  },
  methods: {
    toggle() {
      const showProfile =
        this.$route.query.showProfile === "true" ? "false" : "true";
      this.$router.replace({
        query: { showProfile },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.toggle-profile
  display: flex
  align-items: center
  margin-right: vw(20px)
  cursor: pointer

  .mobile &
    justify-content: center

  &.open
    .toggle-profile__icon
      transform: rotate(180deg)

.toggle-profile__text
  m-font-size(12, 16)
  display: flex
  align-items: center
  margin-right: vw(5px)
  color: $primary-theme-color

  .mobile &
    display: flex
    justify-content: center
    margin-right: 0
    color: $secondary-btn-color
    text-decoration: underline
    font-weight: 700

.toggle-profile__icon
  m-icon("chevron", 15)
  transition: transform 0.15s ease-in-out

  .mobile &
    m-icon("chevron-magenta", 10)
    margin-left: vw(4px)
</style>