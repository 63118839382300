import { getIngredient } from "@/services/api";
import { eventTracker } from "@/services/tracker";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";

const emitEditProfileEvent = async (action, type, id, state) => {
  const perfumes = state.perfumes;
  let data = {};
  const resultingProfile = {
    perfumes: perfumes.map((p) => p.id),
    ingredients: state.ingredients,
  };
  eventTracker.emitEvent(action, {
    id: parseInt(id),
    type,
    data,
    resultingProfile,
  });
};

export default {
  state: {
    perfumes: [],
    ingredients: [],
    mode: null,
    showProfile: false,
    recosHash: true,
  },
  mutations: {
    reset(state) {
      state.perfumes = [];
      state.ingredients = [];
    },
    addPerfume(state, perfume) {
      state.perfumes = [...state.perfumes, perfume];
    },
    removePerfume(state, { id }) {
      state.perfumes = state.perfumes.filter((p) => p.id !== id);
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    addIngredient(state, { id }) {
      state.ingredients = [...state.ingredients, id];
    },
    removeIngredient(state, { id }) {
      state.ingredients = state.ingredients.filter((ppid) => ppid !== id);
    },
    setShowProfile(state, showProfile) {
      state.showProfile = showProfile;
    },
  },
  actions: {
    setShowProfile({ commit }, showProfile) {
      commit("setShowProfile", showProfile);
    },
    setMode({ commit }, mode) {
      commit("setMode", mode);
    },
    addPerfume({ commit, state, dispatch }, perfume) {
      if (!state.perfumes.find((p) => p.id === perfume.id)) {
        const newState = { ...state, perfumes: [...state.perfumes, perfume] };
        emitEditProfileEvent(
          EVENT_NAME_ENUM.ADD_TO_PROFILE,
          "PERFUME",
          perfume.id,
          newState
        );
        commit("addPerfume", perfume);
        dispatch("setNeedsNewRecos");
      }
    },
    removePerfume({ commit, state, dispatch }, perfume) {
      const { id } = perfume;
      if (state.perfumes.find((p) => p.id === id)) {
        const newState = {
          ...state,
          perfumes: state.perfumes.filter((ppid) => ppid !== id),
        };
        emitEditProfileEvent(
          EVENT_NAME_ENUM.REMOVE_FROM_PROFILE,
          "PERFUME",
          perfume.id,
          newState
        );
        commit("removePerfume", { id });
        dispatch("setNeedsNewRecos");
      }
    },
    addIngredient({ commit, state, dispatch }, ingredient) {
      const { id } = ingredient;
      if (state.ingredients.indexOf(ingredient.id) < 0) {
        emitEditProfileEvent(EVENT_NAME_ENUM.ADD_TO_PROFILE, "INGREDIENT", id, {
          ...state,
          ingredients: [...state.ingredients, id],
        });
        commit("addIngredient", ingredient);
        dispatch("setNeedsNewRecos");
      }
    },
    removeIngredient({ commit, state, dispatch }, ingredient) {
      const { id } = ingredient;
      if (state.ingredients.find((ppid) => ppid === id)) {
        emitEditProfileEvent(
          EVENT_NAME_ENUM.REMOVE_FROM_PROFILE,
          "INGREDIENT",
          id,
          {
            ...state,
            ingredients: state.ingredients.filter((ppid) => ppid !== id),
          }
        );
        commit("removeIngredient", { id });
        dispatch("setNeedsNewRecos");
      }
    },
    reset({ commit }) {
      commit("reset");
    },
  },
  getters: {
    perfumes: (state) => () => {
      return state.perfumes;
    },
    ingredients: (state) => () => {
      return Promise.all(
        state.ingredients.map((iid) => {
          return getIngredient(iid);
        })
      );
    },
  },
};
