<template>
  <div
    v-click-outside="()=>open=false"
    class="info-icon-wrapper"
    :class="{'open': open}"
    @mouseenter="()=>setOpen(true)"
    @mouseleave="()=>setOpen(false)"
    @click.stop="()=>open=!open"
  >
    <div class="group-description-icon" />
    <transition name="fade">
      <div v-if="open" class="info-description">
        <div class="description">
          {{ description }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },
  props: {
    description: String,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    setOpen(open) {
      if (!this.isMobile) {
        this.open = open;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
$padding = vw(10px)
$translateX = vw(-11px)

.info-icon-wrapper
  position: relative

  .mobile &
    position: static

  &.open
    .group-description-icon
      m-icon("info-magenta", 25)

      .mobile &
        m-icon("info-close-magenta", 20)

  .group-description-icon
    m-icon("info-empty", 25)
    position: relative
    z-index: 2
    flex: 0 0 vw(25px)
    cursor: pointer

    .mobile &
      m-icon("info-empty", 20)

    &:hover
      .desktop &
        m-icon("info-magenta", 25)

  .info-description
    position: absolute
    top: 50%
    left: 0
    z-index: 1
    display: flex
    align-items: center
    padding: vw(20px)
    max-width: 450px
    width: vw(600px)
    border: 1px solid $magente
    background-color: #fff
    transform: translate($translateX, -50%)

    .mobile &
      top: "calc(100% + %s)" % vw(10px)
      left: 0
      z-index: 10
      padding: vw(10px)
      width: 90vw
      transform: none

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.15s ease-in-out

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

    .description
      m-font-size(14, 18)
      padding-left: vw(40px)

      .mobile &
        padding-left: 0
</style>