import Vue from "vue";
import VueRouter from "vue-router";
import PerfumeSearchVue from "../views/PerfumeSearch.vue";
import IngredientSearchVue from "../views/IngredientSearch.vue";
import RecommendationsVue from "../views/Recommendations.vue";
import PerfumeVue from "../views/Perfume.vue";
import WelcomeVue from "../views/Welcome.vue";
import ModeSelectVue from "../views/ModeSelect.vue";
import { eventTracker } from "../services/tracker";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
Vue.use(VueRouter);

export const ROUTE_NAMES = {
  WELCOME: "Welcome",
  MODE_SELECT: "ModeSelect",
  PERFUME_SEARCH: "PerfumeSearch",
  INGREDIENT_SEARCH: "IngredientSearch",
  RECOMMENDATIONS: "Recommendations",
  PERFUME: "Perfume",
  HOW_IT_WORKS: "HowItWorks",
};

const routes = [
  {
    path: "/welcome",
    alias: "",
    name: ROUTE_NAMES.WELCOME,
    component: WelcomeVue,
    viewEventName: "LANDING_PAGE",
  },
  {
    path: "/mode-select",
    name: ROUTE_NAMES.MODE_SELECT,
    component: ModeSelectVue,
    viewEventName: "FIND_BY",
  },
  {
    path: "/perfume-search",
    name: ROUTE_NAMES.PERFUME_SEARCH,
    component: PerfumeSearchVue,
    viewEventName: "EXPLORE_BY_PERFUMES",
  },
  {
    path: "/ingredient-search",
    name: ROUTE_NAMES.INGREDIENT_SEARCH,
    component: IngredientSearchVue,
    viewEventName: "EXPLORE_BY_INGREDIENTS",
  },
  {
    path: "/recommendations/:mode?",
    name: ROUTE_NAMES.RECOMMENDATIONS,
    component: RecommendationsVue,
    props: true,
    viewEventName: "RECOMMENDATIONS_VIEW",
  },
  {
    path: "/perfume/:id",
    name: ROUTE_NAMES.PERFUME,
    component: PerfumeVue,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const matchingRoute = routes.find((r) => r.name === to.name);
  if (!matchingRoute) {
    next();
    return;
  }
  const viewEventName = matchingRoute.viewEventName;
  if (to.name !== from.name && viewEventName) {
    let data = {};
    if (viewEventName === "PERFUME_VIEWED") {
      data.id = to.params.id;
    }
    eventTracker.emitEvent(EVENT_NAME_ENUM.VIEW_OPENED, {
      type: viewEventName,
      data,
    });
  }
  next();
});

export default router;
