<template>
  <div v-if="texts" class="reco-desc">
    <!-- <div class="intro" v-html="texts.intro"></div> -->
    <div class="content" v-html="texts.content" />
  </div>
</template>

<script>
import { commonIngredients } from '../../utils/profileIngredients';

function firstLetterCase(text) {
  // return text.replace(/^\w/, c => c.toUpperCase())
  return text.charAt(0).toUpperCase() + text.slice(1);
}
function concatWithAnd(array, andString) {
  if (array.length === 1) return array[0];
  if (array.length === 2) return array.join(" " + andString + " ");
  return (
    array.slice(0, -1).join(", ") +
    " " +
    andString +
    " " +
    array[array.length - 1]
  );
}
export default {
  name: "RecoDesc",
  props: ["data"],
  mounted() {},

  asyncComputed: {
    async texts() {
      let descHero, descFamilies, descIng;
      let descs = [];

      // let descIntro
      // if(this.props.isRecomended) descIntro = this.$t('reco_desc_title')
      // else descIntro = this.$t('possible_reco_title')

      let famsAndSubs = await this.$store.getters.familiesWithSubsByPerfumes();
      let commonFams = famsAndSubs.filter((f) => {
        return (
          this.data.family && f.family.id === this.data.family.id //||
          // (this.data.secondaryFamily &&
          //   f.family.id === this.data.secondaryFamily.id)
        );
      });

      if (commonFams.length > 0) {
        descFamilies =
          `${this.$t("it")} ` +
          this.$t("recommended_by_fam").replace(
            "%FAMILY%",
            concatWithAnd(
              commonFams.map((f) => f.family.name).filter((t) => t),
              this.$t("and")
            )
          );
      }
      //  else {
      //   descFamilies = this.$t("not_recommended_by_fam");
      // }

      const proportionSortCompareFn = (a, b) => {
        if (a.proportion > b.proportion) {
          return -1;
        } else if (a.proportion < b.proportion) {
          return 1;
        } else {
          return a.ingredient.name > b.ingredient.name;
        }
      };

      let profileIngs = await this.$store.getters.profileIngredients();
      // sorted in commonIngredients
      let commonIngs = commonIngredients(this.data,profileIngs)
        

      let hero;
      let profileHeroes = await this.$store.getters.getProfileHeroes();
      // let profileMains = Utils.getProfileMainIngredients(this.props.profile)
      let matchingHeroes = this.data.ingredientProportions
        .slice(0, 5)
        .filter(
          (ip) =>
            ip.proportion == 10 &&
            profileHeroes.find(
              (ph) =>
                ph.id === ip.ingredient.id ||
                ph.genericId === ip.ingredient.id ||
                ph.id === ip.ingredient.genericId ||
                (ph.genericId === ip.ingredient.genericId &&
                  ph.genericId != null)
            )
        )
        .sort(proportionSortCompareFn)
        .map((ip) => ip.ingredient);

      if (
        // this.data.type === "hero" ||
        matchingHeroes.length > 0 &&
        commonFams.length > 0
      ) {
        hero = matchingHeroes[0];
        commonIngs = commonIngs.filter((ingredient) =>ingredient.id !== hero.id);
      }

      if (hero) {
        descHero = this.$t("recommended_by_hero").replace("%HERO%", hero.name);
        descs.push(descHero);
      }

      if (hero)
        commonIngs = commonIngs.filter((ingredient) => ingredient.id !== hero.id);
      if (commonIngs.length > 0) {
        descIng = this.$t("recommended_by_ings").replace(
          "%INGS%",
          concatWithAnd(
            commonIngs.map((i) => i.name),
            this.$t("and")
          )
        );
        // descIng = this.$t("recommended_by_ings").replace("%INGS%", "");
        if (descIng) descs.push(descIng);
      }

      // if(this.data.type==='expert') isExpert = true

      let fullDesc;

      if (hero) {
        // if(commonIngs.length>0) fullDesc = descHero +`, ${this.$t('and')} ${this.$t('it')} `+descIng
        if (commonIngs.length > 0)
          fullDesc = descHero + `. <br/> ${this.$t("furthermore")}, ` + descIng;
        else fullDesc = descHero + `.`;
      } else if (this.data.type === "family") {
        if (descIng) fullDesc = descFamilies + `, ${this.$t("and")} ` + descIng;
        else fullDesc = descFamilies;
      } else {
        //if(this.data.type==='ingredient' || this.data.type==='explicit_ingredient' || this.data.type==='expert' || this.data.type==='goodGuy'  || this.data.type==='hero'){
        // if (commonFams.length>0) {
        //   if(descIng) fullDesc =  `${this.$t('it')} ` + descIng //+`, ${this.$t('and')} ${this.$t('it')} `+descFamilies
        //   else fullDesc =  `${this.$t('it')} `+descFamilies
        // } else {
        //   if(descIng) fullDesc = `${this.$t('it')} `+ descIng //+`.<br/><br/>${descFamilies}`
        //   else fullDesc = descFamilies
        // }
        if (commonFams.length > 0) {
          fullDesc =
            `${descFamilies} ${
              descIng ? `, ${this.$t("and")} ${descIng}` : ""
            }` + `.`;
        } else {
          fullDesc = `${descFamilies} ` + descIng ? descIng : "" + `.`;
        }
      }
      fullDesc = firstLetterCase(fullDesc || "");
      return {
        // intro: descIntro+':',
        content: fullDesc,
      };
    },
  },
};
</script>

<style>
</style>