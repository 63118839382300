<template>
  <transition name="fade">
    <div v-if="active" class="loading-overlay">
      <div class="content">
        <!-- <div class="icon" /> -->
        <span class="loader" />
        <!-- <div v-if="text" class="text">{{ $t(text) }}</div> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    text: {
      type: String,
      default: "loading",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    this.$nextTick(() => (this.active = true));
  },
};
</script>

<style scoped lang="stylus">
$radius = vw(60px)
$radius1 = "calc( %s - 1px)" % vw(60px)
$outer-diameter = vw(120px)
$inner-diameter = vw(100px)

.loading-overlay
  position: absolute
  top: $header-height
  left: 0
  z-index: 10
  display: flex
  justify-content: center
  max-height: 100vh
  width: 100%
  height: 100%
  background-color: rgba(#fff, 0.9)

  .mobile &
    top: $header-height-mobile

  &.fade-enter-active
    transition: opacity 0.3s

  &.fade-leave-active
    transition: opacity 0.3s
    transition-delay: 0.3s

  &.fade-enter,
  &.fade-leave-to
    opacity: 0

  .content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .icon
      width: vw(40px)
      height: vw(40px)
      background-image: url("~@/assets/images/loader_spinner.gif")
      background-position: center center
      background-size: contain
      background-repeat: no-repeat

    .loader
      position: relative
      overflow: hidden
      width: 1em
      height: 1em
      border-radius: 50%
      color: $magente
      text-indent: -9999em
      font-size: 45px
      transform: translateZ(0)
      animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease

    @keyframes mltShdSpin
      0%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em

      5%,
      95%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em

      10%,
      59%
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em

      20%
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em

      38%
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em

      100%
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em

    @keyframes round
      0%
        transform: rotate(0)

      100%
        transform: rotate(360deg)

    .text
      m-first-letter-case()
      m-font-size(16, 20)
      margin: vw(5px)
      text-align: center
      font-weight: bold
</style>