<template>
  <div class="perfume-card" @click="onClick">
    <div class="image-container">
      <div class="image" :style="{backgroundImage: `url(${bestImage})`}" />
    </div>
    <families-with-icon v-if="!isTopReco" :family="data.family" :subfamily="data.secondaryFamily" />
    <div class="info">
      <families-with-icon v-if="isTopReco" :family="data.family" :subfamily="data.secondaryFamily" />
      <div class="brand">{{ data.brand.name }}</div>
      <v-clamp class="name" :max-lines="2">{{ data.name }}</v-clamp>
    </div>
    <!-- <div class="price">{{ lowestPrice ? `${$t('from')} ${lowestPrice}Kč` : '' }}</div> -->
    <a
      v-if="buyUrl"
      class="btn btn--primary"
      :href="buyUrl"
      target="_blank"
      @click.stop="onBuyClicked"
    >{{ $t('show_now') }} <span class="sr-only">{{ data.name }}</span></a>
  </div>
</template>

<script>
import FamiliesWithIcon from "./Legacy/FamiliesWithIcon.vue";
import VClamp from "vue-clamp";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";

export default {
  components: {
    FamiliesWithIcon,
    VClamp,
  },
  props: ["data", "showRemove", "isTopReco"],
  computed: {
    hostEans() {
      if (!this.data || !this.data.eanData) return [];
      return Object.values(this.data.eanData).filter((d) => {
        return (
          parseInt(d.hostId) === parseInt(process.env.VUE_APP_HOST_ID) &&
          !!d.price &&
          !!d.productUrl
        );
      });
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = this.hostEans
        .map((a) => a)
        .sort((a, b) => a.price - b.price)[0];
      if (!bestEan) bestEan = this.hostEans[0];
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
    buyUrl() {
      let url = this.bestEan ? this.bestEan.productUrl : null;
      return url
        ? url +
            "?utm_source=fragrance-finder&utm_medium=referrer&utm_campaign=fragrance-finder_buy-link"
        : null;
    },
  },
  methods: {
    onClick() {
      this.$emit("click", this.data.id);
    },
    onBuyClicked() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
        type: "BUY",
        data: {
          id: this.data.id,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.perfume-card
  position: relative
  overflow: hidden
  cursor: pointer

  .desktop &
    padding: vw(20px)
    border: 1px solid transparent
    background-color: #fff

  &:hover
    .desktop &
      border-color: #e6e6e6

  .image-container
    position: relative
    flex: 0 0 vw(250px)
    overflow: hidden
    margin-right: auto
    margin-bottom: vh(15px)
    margin-left: auto
    max-width: 100%
    width: vw(250px)
    height: vh(300px)
    background-color: #f6f6f6

    .mobile &
      flex: 0 0 vw(120px)
      width: vw(120px)
      height: vw(120px)

  .image
    position: absolute
    top: 50%
    left: 50%
    width: 90%
    height: 90%
    background-color: #f6f6f6
    background-position: 50%
    background-size: contain
    background-repeat: no-repeat
    transform: translate(-50%, -50%)
    background-blend-mode: multiply

  .info
    overflow: hidden
    margin-bottom: vh(15px)
    width: 100%

    .name
      m-font-size(16, 24)
      height: vw(48px)
      text-transform: uppercase
      font-weight: 700

    .brand
      m-ellipsis(100%)
      m-font-size(16, 20)
      margin-bottom: vh(8px)
      color: #8F8F8F

  .btn
    display: inline-flex
    padding: vw(10px) vw(20px)
    text-transform: uppercase

    .mobile &
      width: 100%

  .trash-button
    m-icon("cross-black", 16)
    position: absolute
    top: vw(5px)
    right: vw(5px)
    cursor: pointer
</style>