<template>
  <header class="nav-header">
    <nav role="navigation" :aria-label="$t('breadcrumb')" class="nav-header__links breadcrumbs">
      <ul class="nav-header__links">
        <li class="nav-header__link">
          <button class="nav-header__link" @click.prevent="restart">
            <div class="nav-header__link__text">{{ $t('start_new_session') }}</div>
          </button>
          <div aria-hidden="true" class="arrow-right" />  
        </li>
        <li class="nav-header__link">
          <button
            class="nav-header__link"
            :class="{'nav-header__link--active': isActive('search')}"
            :aria-current="isActive('search') ? 'page' : ''"
            @click.prevent="goToSelect"
          >
            <div class="nav-header__link__text">{{ selectStepLabel }}</div>
          </button>
          <div aria-hidden="true" class="arrow-right" />
        </li>
        <li class="nav-header__link">
          <button
            class="nav-header__link"
            :class="{
              'nav-header__link--disabled': isRecosDisabled,
              'nav-header__link--active': isActive('recos')
            }"
            :aria-current="isActive('recos') ? 'page' : ''"
            @click.prevent="goToRecos"
          >
            <div class="nav-header__link__text">{{ $t('your_recommendations') }}</div>
          </button>
        </li>
      </ul>
    </nav>
    <div class="nav-header__info">
      <toggle-profile v-if="!isMobile && isRecosRoute && $store.state.profile.mode !== 'ingredients'" />
      <!-- <div class="info-container" @click="infoToggle">
        <div class="info-icon" />
        <div class="info-text">{{ $t('how_it_works') }}</div>
      </div> -->
    </div>
    <!-- <how-it-works v-if="showInfo" @close="infoToggle" /> -->
  </header>
</template>
<script>
import { ROUTE_NAMES } from "../router";
import ToggleProfile from "./ToggleProfile.vue";
// import HowItWorks from "../views/HowItWorks.vue";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
export default {
  components: { ToggleProfile }, //, HowItWorks },
  data() {
    return {
      showInfo: false,
      ROUTE_NAMES
    };
  },
  computed: {
    selectStepRoute() {
      if (this.$store.state.profile.mode === "ingredients") {
        return ROUTE_NAMES.INGREDIENT_SEARCH;
      } else {
        return ROUTE_NAMES.PERFUME_SEARCH;
      }
    },
    selectStepLabel() {
      if (this.selectStepRoute === ROUTE_NAMES.INGREDIENT_SEARCH) {
        return this.$t("select_ingredients");
      } else {
        return this.$t("select_fragrances");
      }
    },
    isRecosDisabled() {
      if (this.$store.state.profile.mode === "perfumes") {
        return this.$store.state.profile.perfumes.length < 2;
      } else {
        return this.$store.state.profile.ingredients.length < 1;
      }
    },
    isRecosRoute() {
      return this.$route.name === ROUTE_NAMES.RECOMMENDATIONS;
    },
  },
  methods: {
    restart() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.NEW_SESSION);
      this.$store.dispatch("reset");
      this.$router.push({ name: ROUTE_NAMES.WELCOME });
    },
    infoToggle() {
      this.showInfo = !this.showInfo;
    },
    goToSelect() {
      this.$router.push({ name: this.selectStepRoute });
    },
    goToRecos() {
      const mode = this.$store.state.profile.mode;
      this.$router.push(
        `/recommendations/${mode}${
          mode === "perfumes" && !this.isMobile ? "?showProfile=true" : ""
        }`
      );
    },
    isActive(linkId) {
      if (linkId === "recos") {
        return this.$route.name === ROUTE_NAMES.RECOMMENDATIONS;
      }
      if (linkId === "search") {
        return (
          this.$route.name === ROUTE_NAMES.PERFUME_SEARCH ||
          this.$route.name === ROUTE_NAMES.INGREDIENT_SEARCH
        );
      }
      return false;
    },
  },
};
</script>
<style lang="stylus">
.nav-header
  display: flex
  align-items: center
  padding: vh(6px) vw(40px)
  padding-right: vw(20px)
  height: $header-height
  border-bottom: 1px solid rgba(#A7A5A5, 0.3)

  .mobile &
    padding: vw(5px) vw(10px)
    height: vw(40px)
    background-color: #F2F2F2
$gap = vw(10px)
.nav-header__links
  display: flex
  flex: 1 1 0%
  align-items: center
  height: 100%
  gap: $gap

  .mobile &
    overflow: hidden
    gap: vw(2px)
  ul
    display flex
    li
      button 
        white-space nowrap

.nav-header__link
  display: flex
  align-items: center
  height: 100%
  text-align: center
  padding vw(10px) 0

  &:hover
    &:not(.nav-header__link--active)
      .nav-header__link__text
        opacity: 1

  &:not(.nav-header__link--active)
    .nav-header__link__text
      .mobile &
        m-ellipsis(100%)

.nav-header__link__text
  m-font-size(12, 16)
  color: #757575
  

  .mobile &
    m-font-size(11, 13)
    white-space: nowrap

.nav-header__link--active
  cursor: default
  border-bottom 1px solid #000
  .mobile &
    flex-shrink: 0
    // padding: 0 vw(10px)

  .nav-header__link__text
    color: #000

.nav-header__link--disabled
  opacity: 0.5
  pointer-events: none

.nav-header__info
  display: flex
  align-items: center
  margin-left: auto

.arrow-right
  flex-shrink: 0
  flex-basis: vw(24px)
  text-align: center
  margin: 0 $gap

  &:before
    content: "/"

  .mobile &
    flex-basis: vw(12px)

.info-container
  display: flex
  align-items: center
  cursor: pointer

.info-icon
  m-icon("info", 25)

  .mobile &
    m-icon("info", 20)

.info-text
  m-font-size(12, 16)
  margin-left: vw(10px)

  .mobile &
    display: none
</style>