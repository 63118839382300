<template>
  <button
    class="ingredient-item"
    :disabled="!selectable"
    :class="{selected:selectable && value}"
    @click="onClick"
  >
    <div class="image" :style="{backgroundImage: `url(${data.imageUrl || data.lightImageUrl})`, borderColor: data.family.color}" />
    <div class="label">{{ data.name.toLowerCase() }}</div>
    <div v-if="showFamily" class="fam-label">{{ data.family && data.family.name }}</div>
  </button>
</template>

<script>
export default {
  name: "IngredientItem",
  components: {},
  props: ["data", "showFamily", "selectable", "value"],
  data() {
    return {
      // value: false,
      active: false,
    };
  },
  methods: {
    onClick() {
      // if(this.selectable) this.value=!this.value
      this.$emit("select", this.data);
    },
  },
};
</script>

<style scoped lang="stylus">
.ingredient-item
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden
  padding: 1px // sino se corta el "border" del selected

  .image
    position: relative
    margin: 1px // Para asegurar que no se corta "ni un pixel" de la redonda
    margin-bottom: vh(15px)
    max-width: vw(90px)
    width: 100%
    border: 4px solid #000
    border-radius: 50%
    background-position: center center
    background-size: cover
    background-repeat: no-repeat

    .selected&
      &:before
        position: absolute
        margin-top: -2px
        margin-left: -2px
        width: calc(100% + 4px)
        height: calc(100% + 4px)
        border: solid 2px
        border-radius: 50%
        content: ""

    &:after
      display: block
      padding-bottom: 100%
      content: ""

  .label
    m-ellipsis(100%) // para eliminar el white-space: nowrap
    m-font-size(12, 20)
    m-letter-spacing(50)
    m-first-letter-case()
    text-align: center

  .fam-label
    m-ellipsis(100%)
    m-font-size(12, 14)
    margin-top: vh(5px)
    color: #777
    text-align: center
</style>