<template>
  <div class="profile-summary-toggle" @click="$emit('toggle')">{{ value }}</div>
</template>
<script>
export default {
  props: ["value"],
};
</script>
<style lang="stylus" scoped>
.profile-summary-toggle
  position: absolute
  right: 10px
  bottom: -50px
  z-index: 1
  display: flex
  justify-content: center
  align-items: center
  padding-bottom: 0.05em
  width: 40px
  height: 40px
  border-radius: 50%
  background-color: $secondary-btn-color
  box-shadow: 0 0 5px 2px rgba(#8A8A8A, 0.5)
  color: #fff
  font-weight: 700
  font-size: 20px
  cursor: pointer
</style>