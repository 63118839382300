<template>
  <div id="app" lang="en" :class="deviceClass">
    <nav-header v-if="headerIsVisible" />
    <transition-group name="fade" tag="main" class="transition-wrapper">
      <div :key="$router.currentRoute.name+'_'+$router.currentRoute.params.id" class="keep-alive">
        <router-view :key="$router.currentRoute.name+'_'+$router.currentRoute.params.id" />
      </div>
    </transition-group>
  </div>
</template>


<script>
import device from "@/utils/device";
import ResponsiveCss from "./mixins/ResponsiveCss";
import NavHeader from "./components/NavHeader.vue";
import { ROUTE_NAMES } from "./router";
export default {
  components: { NavHeader },
  mixins: [ResponsiveCss],
  computed: {
    headerIsVisible() {
      return (
        (this.$route.name === ROUTE_NAMES.PERFUME_SEARCH &&
          !this.$store.state.user.firstSearch) ||
        this.$route.name === ROUTE_NAMES.INGREDIENT_SEARCH ||
        this.$route.name === ROUTE_NAMES.RECOMMENDATIONS
      );
    },
    deviceClass() {
      if (this.isMobile) return "mobile";
      return device;
    },
  },
};
</script>
<style lang="stylus">
@import "./styles/main"

#app
  display: flex
  flex-direction: column
  overflow: hidden
  width: 100%
  height: 100%
  .sr-only
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

  .header
    position: fixed
    top: 0
    z-index: 1
    width: 100%

  .transition-wrapper
    position: relative
    z-index: 0
    flex: 1 1 0%
    overflow: hidden

    .view
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: #fff

      &.scrollable
        overflow-y: auto
        padding-bottom: vh(220px)

  .keep-alive
    overflow: hidden
    width: 100%
    height: 100%

    &.fade-enter-active
      position: absolute
      top: 0
      z-index: 1
      transition: opacity 0s ease-in-out

    &.fade-leave-active
      position: absolute
      top: 0
      z-index: 0
      opacity: 0
      transition: opacity 0s ease-in-out
      // transition-delay: 0.25s

    &.fade-enter
      opacity: 0

    &.fade-leave-to
      opacity: 0
</style>
