<template>
  <div role="search" class="search-header-container" :class="{ 'first-search': firstSearch && !isIngredientsMode }">
    <h1
      id="search-title"
      class="search-header-title"
      :class="{'sr-only':!firstSearch}"
      v-html="title"
    />
    <div class="search-header">
      <div class="search-icon" />
      <input
        id="search-input"
        ref="input"
        v-model="internalValue"
        class="input-search"
        :class="{'with-close':internalValue}"
        :placeholder="placeholder"
        :aria-label="placeholder"
        @keyup="onKeyUp"
      >
      <label for="search-input" class="hidden">{{ placeholder }}</label>
      <button
        v-if="internalValue"
        class="close-icon"
        :aria-label="$t('close')"
        tabindex="0"
        @click="resetHandler"
      />
    </div>
  </div>
</template>
<script>
import debounce from "debounce";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["value", "placeholder"],
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    firstSearch() {
      return this.$store.state.user.firstSearch;
    },
    isIngredientsMode() {
      return this.$store.state.profile.mode === "ingredients";
    },
    title() {
      switch (this.$store.state.user.gender) {
        case "MU":
          return this.$t("search_m_fragrances");
        case "FU":
          return this.$t("search_f_fragrances");
        default:
          return this.$t("search_u_fragrances");
      }
    },
  },
  watch: {
    internalValue() {
      this.debounceInput();
    },
    value() {
      if (this.value !== this.internalValue) this.internalValue = this.value;
    },
  },
  methods: {
    emitChange() {
      if (this.firstSearch) {
        this.$store.dispatch("setFirstSearch", false);
      }
      this.$emit("change", this.internalValue);
    },
    debounceInput: debounce(function () {
      this.emitChange();
    }, 500),
    onKeyUp(e) {
      if (e.keyCode === 13) this.$refs.input.blur();
    },
    resetHandler() {
      this.internalValue = "";
    },
  },
};
</script>
<style lang="stylus" scoped>
$search-header-height = 55

.search-header-container
  &:not(.first-search)
    .desktop &
      .search-header
        padding-left: vw(20px)

  &.first-search
    position: absolute
    top: 50%
    left: 50%
    z-index: 2
    transform: translate(-50%, -50%)

    .mobile &
      margin-top: -5%
      width: 90%

    .search-header
      overflow: hidden
      width: 100%
      transition border ease .3s
      

.search-header
  position relative    
  display: flex
  align-items: center
  width: 100%
  height: vw($search-header-height)
  $icon-size-mobile = 40
  $icon-close-size = 20
  $icon-close-size-mobile = 15
  $icon-close-margin = 20
  .input-search 
    transition border ease .1s
    border: 1px solid #ffffff
    border-bottom: 1px solid #E6E6E6
    width 100%
    padding-left: vw($search-header-height)
    
    &:focus
      border: 1px solid #636363
      border-radius 2px
    .mobile &
      padding-left: vw($icon-size-mobile)
      
    &.with-close
      padding-right: vw($icon-close-size + $icon-close-margin*2)
      .mobile &
        padding-right: vw($icon-close-size-mobile + $icon-close-margin*2)
    
  .search-icon
    m-icon("search-fill", $search-header-height)
    background-size: 100%
    position absolute

    .mobile &
      m-icon("search-fill", $icon-size-mobile)
  .close-icon
    position absolute
    right: 0
    m-icon("cross-black", $icon-close-size)
    margin-right: vw(20px)
    margin-left: vw(20px)
    cursor: pointer

    .mobile &
      m-icon("cross-black", $icon-close-size-mobile)
      margin-right: vw(10px)
      margin-left: vw(20px)

  .mobile &
    height: vw(40px)
  label
      &.hidden
        position fixed
        opacity 0

  input
    flex: 1 1 0%
    padding: 0
    height: 100%
    outline: none
    border: none

.search-header-title
  m-font-size(30, 46)
  m-letter-spacing(10)
  margin-bottom: vh(40px)
  color: $primary-theme-color
  &.hidden
    position fixed
    opacity 0

  .mobile &
    m-font-size(24, 30)
    margin-right: auto
    margin-left: auto
    // max-width: vw(200px)


</style>