<template>
  <h2 v-if="data" class="profile-families">
    <div v-if="families.length > 0" class="text-families" v-html="familiesText" />
    <div v-if="filteredSubFamilies.length > 0" class="text-subfamilies" v-html="subFamiliesText" />
  </h2>
</template>
<script>
export default {
  props: ["data"],
  computed: {
    families() {
      return this.data.map(({ family }) => family.name);
    },
    subFamilies() {
      return this.filterUnique(
        this.data.flatMap(({ subFamilies }) =>
          subFamilies ? subFamilies.map((sf) => sf.subFamily.name) : []
        )
      );
    },
    filteredSubFamilies() {
      return this.subFamilies.filter((sf) => !this.families.includes(sf));
    },
    familiesText() {
      return this.$t("your_olfactory_profile_families").replace(
        "%s",
        this.parseStringList(this.families)
      );
    },
    subFamiliesText() {
      return this.$t("your_olfactory_profile_subfamilies").replace(
        "%s",
        this.parseStringList(this.filteredSubFamilies)
      );
    },
  },
  methods: {
    filterUnique(list) {
      return list.filter((c, index) => list.indexOf(c) === index);
    },
    parseStringList(list) {
      if (list.length <= 2) {
        return list.join(" and ");
      }
      return (
        list.slice(0, list.length - 1).join(", ") +
        ` and ${list[list.length - 1]}`
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.text-families
  m-font-size(32, 42)
  margin-bottom: vh(15px)
  color: $primary-theme-color

  .mobile &
    m-font-size(20, 28)

.text-subfamilies
  m-font-size(16, 28)

  .mobile &
    m-font-size(14, 25)
</style>