import en from "./lang/en.js";
import store from "@/store";

let storeInstance = store;
let allStrings = {
  en: { ...en },
};
function flatT(key) {
  return allStrings[storeInstance.state.i18n.lang][key];
}
function replaceArticles(str) {
  let keys, propperKey, propperKeyNoG, g, w;
  if (!str || str.indexOf("$") < 0 || str.indexOf("[") === 0) return str;
  else keys = str.match(/\$[a-zA-Z_]*/g);
  // g = store.getState().session.gender
  // if(g==='U') g = 'M'
  g = "M"; //Remember this is diferents that GENDER_ENUM.MALE. This is in order to find the key in lang
  // w = store.getState().session['for-who']
  w = "self";
  return keys.reduce((result, key) => {
    propperKey = w === "self" ? key + "_self" : key + "_other_" + g;
    propperKeyNoG = w === "self" ? key + "_self" : key + "_other";
    return result.replace(
      key,
      flatT(propperKey) || flatT(propperKeyNoG) || "[" + propperKey + "]"
    );
  }, str);
}
export function t(key) {
  // return allStrings[storeInstance.state.i18n.lang][key]|| '['+key+']'
  // lo anterior no funciona pq el string buit es un falsy
  let aux = replaceArticles(allStrings[storeInstance.state.i18n.lang][key]);
  return aux != undefined ? aux : "[" + key + "]";
}
export default {
  methods: {
    $t: t,
  },
};
