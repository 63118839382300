<template>
  <div class="view perfume">
    <button class="back-btn" @click="$router.back()">
      <div class="back-icon" />
      {{ $t('back') }}
    </button>
    <div v-if="data" class="content">
      <div class="left">
        <div class="info">
          <div class="name">{{ data.name }}</div>
          <div class="brand">{{ data.brand.name }}</div>
          <share-component :data="data" />
        </div>
        <div class="image-container">
          <div class="image" :style="{backgroundImage: `url(${bestImage})`}" />
        </div>
        <div class="price-container">
          <div class="from" v-html="$t('from')" />
          <div class="price">{{ productPrice ? ('$'+productPrice):'' }}</div>
        </div>
        <div class="actions">
          <a
            v-if="buyUrl"
            class="btn btn--primary"
            :href="buyUrl"
            target="_blank"
            @click.stop="onBuyClicked"
          >{{ $t('buy_it_now') }} <span class="sr-only">{{ data.name }}</span></a>
        </div>
      </div>
      <div class="right">
        <quad-pack :proportions="data.ingredientProportions" />
        <div class="ings-title">{{ $t('complete_ingredients_list') }}</div>
        <div class="ingredients">
          <div v-for="ing in data.ingredientProportions" :key="ing.id" class="ingredient">
            {{ ing.ingredient.name }}
          </div>
        </div>
        <div v-if="profileIngredients" class="why-container">
          <div class="why-title" v-html="$t('why_do_we_recommend')" />
          <reco-desc :data="data" />
          <div v-if="mainMatchIngs && mainMatchIngs.length" class="ings-group main-ings">
            <div class="ings-list">
              <ingredient-item v-for="ing in sortedMainMatchIngs" :key="ing.id" :data="ing" />
            </div>
          </div>
          <!-- de moment ho deixo així pq es dificil fer lordre que toca i controlar els diferents casos de reco -->
          <!-- <div v-if="otherMatchIngs && otherMatchIngs.length" class="ings-group other-ings">
            <div class="ings-title-extra">{{ $t('other_ings_present') }}</div>
            <div class="ings-list">
              <ingredient-item v-for="ing in otherMatchIngs" :key="ing.id" :data="ing" />
            </div>
          </div> -->
        </div>
        <button class="evaluate-btn" @click="showEvaluate=true">{{ $t('evaluate_this_reco') }}</button>
      </div>
    </div>
    <evaluate-drawer :is-visible="showEvaluate" @submit="onEvaluated" @close="showEvaluate=false" />
  </div>
</template>

<script>
import { getPerfume } from "@/services/api";
import { QuadPack as QuadPack } from "@bestiario/puig-quad-pack";
import EvaluateDrawer from "../components/EvaluateDrawer.vue";
import RecoDesc from "../components/Legacy/RecoDesc.vue";
import IngredientItem from "../components/Legacy/IngredientItem.vue";
import ShareComponent from "../components/ShareComponent.vue";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
import { commonIngredients } from "../utils/profileIngredients";
export default {
  components: {
    QuadPack,
    EvaluateDrawer,
    RecoDesc,
    IngredientItem,
    ShareComponent,
  },
  props: [],
  data() {
    return {
      showEvaluate: false,
    };
  },
  asyncComputed: {
    data() {
      return getPerfume(this.$router.currentRoute.params.id);
    },
    profileIngredients() {
      return this.$store.getters.profileIngredients();
    },
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
  },
  computed: {
    mainMatchIngs() {
      return commonIngredients(this.data,this.profileIngredients)
    },
    sortedMainMatchIngs() {
      const aux = this.mainMatchIngs;
      return aux.sort((aMatch, bMatch) => {
        if (aMatch.proportion > bMatch.proportion) {
          return -1;
        } else if (aMatch.proportion < bMatch.proportion) {
          return 1;
        } else {
          return aMatch.name > bMatch.name;
        }
      });
    },
    otherMatchIngs() {
      return this.data.ingredientProportions
        .slice(5)
        .filter(
          (prop) =>
            this.profileIngredients.findIndex(
              (ing) =>
                ing.id === prop.ingredient.id ||
                ing.synonymId === prop.ingredient.id ||
                ing.id === prop.ingredient.synonymId ||
                (ing.synonymId === prop.ingredient.synonymId &&
                  prop.ingredient.synonymId)
            ) >= 0
        );
    },
    hostEans() {
      if (!this.data || !this.data.eanData) return [];
      return Object.values(this.data.eanData).filter((d) => {
        return (
          parseInt(d.hostId) === parseInt(process.env.VUE_APP_HOST_ID) &&
          !!d.price &&
          !!d.productUrl
        );
      });
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = this.hostEans
        .map((a) => a)
        .sort((a, b) => a.price - b.price)[0];
      if (!bestEan) bestEan = this.hostEans[0];
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
    buyUrl() {
      let url = this.bestEan ? this.bestEan.productUrl : null;
      return url
        ? url +
            "?utm_source=fragrance-finder&utm_medium=referrer&utm_campaign=fragrance-finder_buy-link"
        : null;
    },
    productPrice() {
      return this.bestEan && this.bestEan.availability > 0
        ? this.bestEan.price
        : null;
    },
    productSize() {
      return this.bestEan ? this.bestEan.size : null;
    },
    isInProfile() {
      return (
        this.profilePerfumes &&
        this.profilePerfumes.find((p) => p.id === this.data.id)
      );
    },
  },
  watch: {
    data() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.VIEW_OPENED, {
        type: "PERFUME_VIEWED",
        from: this.$route.query.fromReco ? "RECOMMENDATIONS_VIEW" : undefined,
        data: {
          id: this.data.id,
          sku: this.bestEan ? this.bestEan.sku : null,
        },
      });
    },
  },
  mounted() {},
  methods: {
    onEvaluated(rating) {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
        type: "EVALUATE",
        data: {
          id: this.data.id,
          sku: this.bestEan ? this.bestEan.sku : null,
          rating,
        },
      });
      this.showEvaluate = false;
    },
    onBuyClicked() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
        type: "BUY",
        data: {
          id: this.data.id,
          sku: this.bestEan ? this.bestEan.sku : null,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.perfume
  display: flex
  flex-direction: column

  .back-btn
    m-font-size(14, 22)
    position: absolute
    top: vh(30px)
    left: vw(30px)
    z-index: 2
    align-self: flex-start
    padding: vw(10px) vw(20px)
    padding-top: vw(11px)
    padding-left: vw(16px)
    border: 1px solid #BFBFBF
    border-radius: 2px
    background: white
    background-color: #fff
    color: #000
    cursor: pointer

    .mobile &
      top: 0
      left: 0
      margin-top: vw(10px)
      margin-bottom: vw(10px)
      margin-left: vw(10px)

  .content
    position: relative
    display: flex
    flex: 1 1 0%
    overflow: hidden
    margin-right: auto
    margin-left: auto
    max-width: 1440px

    .left,
    .right
      padding: vh(30px) vw(30px)

    .right
      padding-right: vw(90px)

  .left
    $margin = vw(20px)
    display: flex
    flex: 0 0 "calc(50% - %s)" % $margin
    flex-direction: column
    margin-top: vh(80px) !important
    margin-right: $margin

    .mobile &
      margin-top: vw(60px) !important

    .info
      position: relative

      .name
        m-font-size(28, 33)
        margin-right: vw(40px)
        margin-bottom: vh(2px)
        font-weight: 700

        .mobile &
          m-font-size(20, 24)

      .brand
        m-font-size(24)
        margin-right: vw(40px)

        .mobile &
          m-font-size(14, 20)

    .image-container
      flex: 1 1 0%
      margin: vh(20px) auto
      width: 75%

      .mobile &
        margin: vw(20px) auto

    .image
      width: 100%
      height: 100%
      background-position: center center
      background-size: contain
      background-repeat: no-repeat

    .price-container
      display: flex
      justify-content: space-between
      align-items: flex-end
      margin-bottom: vh(15px)
      padding-bottom: vh(10px)
      border-bottom: 1px solid #A7A5A5

      .mobile &
        margin-bottom: 0
        padding-bottom: vw(10px)

    .from
      m-font-size(16, 20)

    .price
      m-font-size(30, 36)
      m-letter-spacing(70)
      align-self: flex-end
      font-weight: 700

    .actions
      .btn
        margin-right: auto
        margin-left: auto
        max-width: 75%

        .mobile &
          max-width: 100%

  .right
    $margin = vw(20px)
    flex: 0 0 "calc(50% - %s)" % $margin
    overflow-y: auto
    margin-left: $margin
    padding-top: vh(80px + 30px) !important
    padding-right: vw(40px)
    padding-left: vw(40px)

    .mobile &
      padding-top: 0 !important

    .quad-pack
      margin-bottom: vh(30px)
      max-width: 75vh
      max-height: 75vh

    .ings-title
      m-font-size(20, 24)
      m-letter-spacing(15)
      margin-bottom: vh(15px)
      font-weight: 700

      .mobile &
        margin-bottom: vw(20px)

    .ingredients
      display: flex
      flex-wrap: wrap
      margin-bottom: vh(40px)

      .ingredient
        m-font-size(16, 20)
        m-letter-spacing(25)
        margin-right: vw(30px)
        margin-bottom: vh(20px)

        .mobile &
          margin-bottom: vw(15px)

        &:not(:last-child)
          position: relative

          &:after
            position: absolute
            top: 0
            right: vw(-30px)
            margin-right: vw(15px)
            margin-left: vw(15px)
            width: 1px
            height: 100%
            background-color: rgba(0, 0, 0, 0.25)
            content: ""

    .why-container
      margin-bottom: vh(20px)

      .why-title
        m-font-size(20, 24)
        m-letter-spacing(15)
        margin-bottom: vh(15px)
        font-weight: 700

        .mobile &
          margin-bottom: vw(10px)
          padding-top: vw(15px + 0.25em)
          padding-bottom: vw(15px)
          border-top: 1px solid #ddd
          border-bottom: 1px solid #ddd

      .reco-desc
        m-font-size(14, 20)
        m-letter-spacing(20)
        margin-bottom: vh(20px)

        .mobile &
          m-font-size(16, 24)
          margin-bottom: vw(20px)

      .ingredient-item
        $gap = 10px
        $width = percentage((1 / 4))
        flex: 0 0 "calc(%s - (%s * 2))" % ($width vw($gap))
        margin-right: vw($gap)
        margin-bottom: vw($gap * 2)
        margin-left: vw($gap)

        .mobile &
          $gap = 10px
          $width = percentage((1 / 3))
          flex: 0 0 "calc(%s - (%s * 2))" % ($width vw($gap))
          margin-right: vw($gap)
          margin-bottom: vw($gap * 2)
          margin-left: vw($gap)

      .ings-group
        margin-bottom: vh(20px)

        .ings-list
          display: flex
          flex-wrap: wrap
          margin-right: vw(-10px)
          margin-left: vw(-10px)

      .main-ings,
      .other-ings
        .mobile &
          margin-bottom: vw(20px)

    .ings-title-extra
      m-font-size(16, 20)
      m-letter-spacing(20)
      margin-bottom: vh(20px)
      font-weight: 700

      .mobile &
        margin-bottom: vw(20px)

    .description
      margin-bottom: vh(30px)

      .mobile &
        margin-bottom: vw(20px)

      .desc-title
        font-weight: 700
        m-font-size(16, 20)
        m-letter-spacing(20)
        margin-bottom: vh(20px)

        .mobile &
          margin-bottom: vw(15px)

      .desc-content
        color: $tundora

        .mobile &
          m-font-size(16, 20)

    .evaluate-btn
      m-font-size(14, 20)
      m-letter-spacing(20)
      margin-bottom: vh(40px)
      color: $primary-theme-color
      text-decoration: underline
      font-weight: 700
      cursor: pointer

      .mobile &
        margin-top: vw(20px)
        margin-bottom: 0
        text-align: center

  .mobile &
    .content
      display: block
      flex-direction: column
      overflow-y: auto
      max-width: 100%

      .left,
      .right
        margin: 0

      .left
        padding: vw(20px)

        .image-container
          position: relative
          flex: 0 0 80vw

          >>> .image
            position: absolute
            top: 0
            left: 0

        .actions
          margin-top: vw(20px)

      .right
        overflow-y: visible
        padding: vw(20px)
        padding-bottom: vw(40px)

        .quad-title
          font-weight: $fw-medium
          m-font-size(16, 20)
          margin-bottom: vw(20px)

        .quad-pack
          margin-bottom: vw(30px)

.back-icon
  m-icon("back", 16)
  margin-top: -0.15em
  margin-right: vw(10px)
</style>