import device from "@/utils/device";
export default {
  computed: {
    isMobile() {
      // #19 custom user agent for web-view in android/ios app
      const userAgent = navigator.userAgent;
      console.log(userAgent, userAgent.includes("ulta-app-android"));
      return (
        device === "mobile" ||
        userAgent.includes("ulta-app-ios") ||
        userAgent.includes("ulta-app-android")
      );
    },
    device() {
      if (this.isMobile) return "mobile";
      return device;
    },
  },
};
