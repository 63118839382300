export function commonIngredients(perfume, profileIngredients) {
  const ingredients = profileIngredients
    .filter((ing) => {
      const ingProportion = perfume.ingredientProportions.find(
        (prop) =>
          ing.id === prop.ingredient.id ||
          ing.genericId === prop.ingredient.id ||
          ing.id === prop.ingredient.genericId ||
          (ing.genericId === prop.ingredient.genericId &&
            prop.ingredient.genericId)
      );
      if (ingProportion) ing.proportion = ingProportion.proportion;
      return !!ingProportion;
    })
    .sort((aMatch, bMatch) => {
      if (aMatch.proportion > bMatch.proportion) {
        return -1;
      } else if (aMatch.proportion < bMatch.proportion) {
        return 1;
      } else {
        return aMatch.name > bMatch.name;
      }
    });
  return ingredients;
}
