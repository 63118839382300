<template>
  <div v-if="(mode==='perfumes' && profilePerfumes) || (mode==='ingredients' && profileIngredients)" class="profile-summary">
    <div class="profile-summary-header">
      <div v-if="mode !== 'ingredients' || profileIngredients.length > 0" class="text">
        <h2>
          <span id="your-selection" class="text-main">{{ $t('your_selection') }}</span>
          <span class="text-sup">{{ `${mode === 'perfumes' ? profilePerfumes.length : profileIngredients.length}` }}</span>
        </h2>
      </div>
      <button
        v-if="mode !== 'ingredients'"
        id="profile-accordion"
        class="header-icons"
        :class="{'summary': showSummary}"
        :aria-label="$t('show_summary')"
        :aria-expanded="(!showSummary).toString()"
        aria-controls="profile"
        @click="showSummary = !showSummary"
      >
        <div
          class="icon-selection-list"
          :aria-selected="showSummary"
          :class="{'disabled': !showSummary}"
        />
        <div
          class="icon-donut"
          :class="{'disabled': showSummary}"
          :aria-selected="!showSummary"
        />
      </button>
      <div
        v-if="isMobile"
        class="close-btn"
        :aria-label="$t('close')"
        tabindex="0"
        @click="$emit('mobileClose')"
      />
    </div>
    <div class="profile-summary-body" :class="{'show-summary': showSummary}">
      <transition name="slide">
        <div
          v-if="!isMobile && showSummary || mobileOpen"
          id="profile"
          class="overlay"
          role="region"
          labelledby="your-selection" 
        >
          <div v-if="mode==='perfumes' && showSummary" class="perfumes-summary">
            <div class="overlay-list">
              <profile-edit-item
                v-for="perfume in profilePerfumes"
                :key="perfume.id"
                :data="perfume"
                type="perfume"
              />
            </div>
          </div>
          <div v-if="mode==='ingredients'" class="ingredients-summary">
            <div class="overlay-list">
              <profile-edit-item
                v-for="ing in profileIngredients"
                :key="ing.id"
                :data="ing"
                type="ingredient"
              />
            </div>
          </div>
        </div>
      </transition>
      <div v-if="donutData && (!isMobile || mobileOpen) && mode !== 'ingredients'" class="donut-container">
        <Donut :data="donutData" />
        <profile-families :data="donutData" />
      </div>
    </div>
    <div class="profile-summary-footer">
      <button
        class="btn btn--primary"
        type="button"
        :aria-label="$t('get_recommendations')"
        :class="{disabled: isRecosDisabled}"
        @click="goToRecos"
      >
        {{ $t('get_recommendations') }}
      </button>
    </div>
  </div>
</template>

<script>
import Donut from "@/components/Legacy/Donut";
import ProfileEditItem from "./ProfileEditItem.vue";
import ProfileFamilies from "./ProfileFamilies.vue";
export default {
  components: {
    Donut,
    ProfileFamilies,
    ProfileEditItem,
  },
  props: ["mode", "mobileOpen"],
  data() {
    return {
      showSummary: true,
    };
  },
  computed: {
    isRecosDisabled() {
      if (this.$store.state.profile.mode === "perfumes") {
        return this.$store.state.profile.perfumes.length < 2;
      } else {
        return this.$store.state.profile.ingredients.length < 1;
      }
    },
  },
  asyncComputed: {
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
    profileIngredients() {
      return this.$store.getters.ingredients();
    },
    donutData() {
      if (this.$route.name === "IngredientSearch")
        return this.$store.getters.familiesWithSubsByIngredients();
      else return this.$store.getters.familiesWithSubsByPerfumes();
    },
    fragranceCount() {
      return this.profilePerfumes ? this.profilePerfumes.length : 0;
    },
    ingredientsCount() {
      return this.profileIngredients ? this.profileIngredients.length : 0;
    },
  },
  methods: {
    goToRecos() {
      const mode = this.$store.state.profile.mode;
      this.$router.push(
        `/recommendations/${mode}${
          mode === "perfumes" && !this.isMobile ? "?showProfile=true" : ""
        }`
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
$header-height = vh(60px)

.profile-summary
  position: relative
  display: flex
  flex-direction: column
  height: 100%

  >>> .text-families
    m-font-size(20, 28)

  >>> .text-subfamilies
    m-font-size(14, 25)

.profile-summary-header
  z-index: 2
  display: flex
  justify-content: space-between
  align-items: center
  padding: vw(40px) vw(40px) vw(20px)
  height: $header-height

  .mobile &
    display: none

  &.disabled
    opacity: 0.4

  // .text
  .text-main
    m-font-size(30, 36)
    color: $primary-theme-color

  .text-sup
    m-font-size(20, 24)
    margin-left: 0.2em
    color: $primary-theme-color
    vertical-align: super

  .header-icons
    display: flex
    align-items: center
    cursor: pointer
    gap: vw(10px)

    .mobile &
      margin-bottom: vw(-5px)

    &:hover
      &:not(.summary)
        .icon-selection-list
          opacity: 1

      &.summary
        .icon-donut
          opacity: 1

.profile-summary-body
  position: relative
  flex: 1 1 0%
  overflow: hidden

.profile-summary-footer
  padding: vw(20px)

  .mobile &
    display: flex
    align-items: center
    padding: vw(10px)
    width: 100%
    height: vw(80px)

    .btn
      width: 100%

.donut-container
  display: flex
  flex-direction: column
  padding: vw(20px)
  height: 100%

  .mobile &
    padding-top: vw(20px)
    padding-bottom: vw(20px)

  .donut-wrapper
    display: flex
    flex-direction: column

    >>> .donut
      position: relative
      flex: 1 1 0%

.overlay
  position: absolute
  top: 0
  left: 0
  z-index: 1
  overflow-y: auto
  padding: vw(40px)
  padding-top: vw(20px)
  width: 100%
  height: 100%
  background-color: #f6f6f6

  &.slide-enter-active,
  &.slide-leave-active
    transition: transform 0.5s

  &.slide-enter,
  &.slide-leave-to
    transform: translate(100%, 0)

  .mobile &
    top: 100%
    z-index: 0
    padding: vw(20px)

    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.5s

    &.slide-enter,
    &.slide-leave-to
      transform: translate(0, 10%)

  .overlay-list
    flex: 1 1 0%
    overflow-y: auto

.icon-selection-list
  m-icon("selection-list-active", 25)
  cursor: pointer

  &.disabled
    m-icon("selection-list", 25)
    opacity: 0.5

.icon-donut
  m-icon("donut-active", 25)
  cursor: pointer

  &.disabled
    m-icon("donut", 25)
    opacity: 0.5

.close-btn
  m-icon("cross-black", 18)
  position: absolute
  top: vh(20px)
  right: vw(20px)
  cursor: pointer

  .mobile &
    top: vw(60px)
</style>