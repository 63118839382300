import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import AsyncComputed from "vue-async-computed";
import { EventTrackerPlugin } from "./services/tracker";
import VueSocialSharing from "vue-social-sharing";

import DeviceDetect from "./mixins/DeviceDetect";
import i18n from "./i18n";

Vue.mixin(DeviceDetect);
Vue.mixin(i18n);
Vue.config.productionTip = false;
Vue.use(AsyncComputed);
Vue.use(EventTrackerPlugin);
Vue.use(VueSocialSharing);

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener("scroll", f);
      }
    };
    el.addEventListener("scroll", f);
  },
});
store.dispatch("setLanguage", "en");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
