<template>
  <div class="evaluate-drawer" :class="{'visible': isVisible}">
    <div class="overlay" />
    <div class="drawer">
      <div
        class="close"
        :aria-label="$t('close')"
        tabindex="0"
        @click="onClose"
      />
      <div class="description" v-html="$t('how_relevant_do_you')" />
      <div class="buttons">
        <button
          v-for="i in [0,1,2,3,4,5]"
          :key="i"
          class="rate-button"
          :class="{active:currentRating!=null && i<=currentRating}"
          @click="currentRating=i"
        >
          {{ i }}
        </button>
      </div>
      <button class="submit btn btn--primary" @click="onSubmit">{{ $t('send') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isVisible"],
  data() {
    return {
      currentRating: null,
      // visible: false,
    };
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.visible = true;
  //   });
  // },
  methods: {
    hide() {
      this.visible = false;
      return new Promise((res) => {
        setTimeout(() => {
          res();
        }, 250);
      });
    },
    onClose() {
      this.hide().then(() => this.$emit("close"));
    },
    onSubmit() {
      this.visible = false;
      this.hide().then(() => this.$emit("submit", this.currentRating));
    },
  },
};
</script>

<style lang="stylus" scoped>
.evaluate-drawer
  position: fixed
  top: 0
  z-index: 3
  width: 100%
  height: 100%
  transition: transform 0s ease-in-out
  transition-delay: 0.5s
  transform: translateY(100%)

  &.visible
    transition-delay: 0s
    transform: translateY(0)

    .overlay
      opacity: 1

    .drawer
      transition-delay: 0.25s
      transform: translateY(0)

  .overlay
    position: absolute
    overflow: hidden
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.3)
    opacity: 0
    transition: opacity 0.25s ease-in-out
    // transition-delay: 0.25s

  .drawer
    position: absolute
    bottom: 0
    display: flex
    flex-direction: column
    align-items: center
    padding: vw(30px)
    width: 100%
    background-color: #F4F4F4
    color: $primary-theme-color
    transition: transform 0.25s ease-in-out 0.25s
    transition-delay: 0s
    transform: translateY(100%)

    .mobile &
      padding: vw(30px)

    .close
      m-icon("close", 20)
      position: absolute
      top: vw(30px)
      right: vw(30px)
      cursor: pointer

      .mobile &
        top: vw(30px)

    .description
      m-font-size(25, 30)
      margin-bottom: vh(30px)
      text-align: center

      .mobile &
        m-font-size(18, 27)
        margin-right: vw(50px)
        margin-bottom: vw(20px)
        width: "calc(100% - %s)" % vw(50px)
        text-align: left

    .buttons
      display: flex
      justify-content: space-between
      margin-bottom: vh(30px)
      width: vw(400px)

      .mobile &
        margin-bottom: vw(20px)
        width: 100%

      .rate-button
        display: flex
        justify-content: center
        align-items: center
        width: vw(40px)
        height: vw(40px)
        border: 1px solid $primary-theme-color
        border-radius: 4px
        background-color: #fff
        cursor: pointer

        &:hover,
        &.active
          background-color: $primary-theme-color
          color: #fff

    .submit
      display: inline-flex
      text-transform: uppercase
      pssing: 0.5 1em

      .mobile &
        margin-right: 0
        margin-left: 0
        width: 100%
</style>