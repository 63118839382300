const levenshtein = require('js-levenshtein');

export function matchWords(a, b) {
  const newB = b.toLowerCase()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "") //Remove accents - NFD => Forma de Normalización de Descomposición Canónica.
  const newA = a.toLowerCase()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "") //Remove accents
  const splitedB = newB.split(' ').filter(e => e!=='')
  const splitedA = newA.split(' ').filter(e => e!=='')
  
  if (newA.toLowerCase().indexOf(newB.toLowerCase()) >= 0) return true
  
  const result = splitedB.every(e => splitedA.some(t => t.indexOf(e)>-1 || levenshtein(e,t)<2))
  if(a.length >= 5 && b.length >= 5  && result) return true
  //else if (a.length >= 5 && b.length >= 5 && levenshtein(a, b) - Math.abs(b.length - a.length) < 2) return true
  return false
}

export function capitalize(string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}
