<template>
  <div itemscope itemtype="http://schema.org/PropertyValue" class="family-and-icon">
    <div class="icon">
      <template v-if="subfamily">
        <div class="outer" :style="{background: family.color}" />
        <div class="inner" :style="{background: subfamily ? subfamily.color:family.color}" />
      </template>
      <template v-else>
        <div class="inner" :style="{background: family.color}" />
      </template>
    </div>
    <meta itemprop="name" content="family">
    <!-- <div itemprop="value" class="label" v-tooltip.top="label">{{label}}</div> -->
    <div itemprop="value" class="label">{{ label }}</div>
  </div>
</template>

<script>
import { VTooltip } from "v-tooltip";
export default {
  name: "FamilyWithIcon",
  components: {},
  directives: {
    tooltip: VTooltip,
  },
  props: ["family", "subfamily"],
  computed: {
    label() {
      if (this.family.name) {
        if (this.subfamily)
          return this.family.name + " / " + this.subfamily.name;
        return this.family.name;
      } else return "";
    },
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
.family-and-icon
  display: flex
  align-items: center

  // margin-bottom: vh(8px)
  .icon
    position: relative
    margin-right: vw(5px)
    width: vw(20px)
    // added 2 extra pixels for deciaml cutting
    height: vw(20px)

    .mobile &
      margin-right: vw(5px)

    .outer,
    .inner
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

    .outer
      width: vw(18px)
      height: vw(18px)
      border-radius: 50%

      .mobile &
        width: vw(15px)
        height: vw(15px)

    .inner
      width: vw(10px)
      height: vw(10px)
      border: solid 1px rgba(255, 255, 255, 1)
      border-radius: 50%

      &:only-child
        width: vw(15px)
        height: vw(15px)

        .mobile &
          width: vw(15px)
          height: vw(15px)

      .mobile &
        width: vw(7px)
        height: vw(7px)

  .label
    m-ellipsis(100%)
    m-font-size(12, 16)
    flex: 1 1 0%
    align-items: center
    margin-top: 0.25em

    .mobile &
      m-font-size(11, 14)
</style>