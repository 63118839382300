import { eventTracker } from "@/services/tracker";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
export default {
  state: {
    lang: "en",
  },
  mutations: {
    setLanguage(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    setLanguage(context, lang) {
      eventTracker.emitEvent(EVENT_NAME_ENUM.LANGUAGE_CHANGED, { lang });
      localStorage.setItem("lang", lang);
      context.commit("setLanguage", lang);
    },
  },
};
